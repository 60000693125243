/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

// @font-face {
//   font-family: CentraleSansRegular;
//   src: url(assets/font/CentraleSansRegular.otf) format("opentype");
// }

// @font-face {
//   font-family: CentraleSansBold;
//   src: url(assets/font/CentraleSansBold.otf) format("opentype");
// }

// @font-face {
//   font-family: trebuchet;
//   src: url(assets/font/trebuc.ttf) format("opentype");
// }

// @font-face {
//   font-family: trebuchet-italic;
//   src: url(assets/font/Trebuchet-MS-Italic.ttf) format("opentype");
// }


* {
  font-family: "Inter", sans-serif !important;
}
:root {
  --primary-color: #071279;
  --background-color: #cacdd1;
  --text-color: #272b41;
  --font-family: "Poppins", sans-serif;
  --font-size: 0.9375rem;
  // --primary-color:#071279;
  --app-white-color: #fafafc;
  --app-green-color: #2a9241;
}

html,
body {
  margin: 0;
  height: 100%;
  min-height: 100% !important;
  width: 100% !important;
  background-color: #93969b1a !important;
  color: var(--primary-color) !important;
  font-family: "Inter", sans-serif !important;
  font-size: var(--font-size) !important;
  overflow: hidden;
}

*:not(mat-icon, .material-icons, svg, .fa, img),
.font-poppins {
  font-family: "Inter", sans-serif !important;
}
::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #071279 !important; /* Changes the selected radio button color */
}

::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #071279 !important;
}
::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #071279 !important; /* Changes the filled inner circle */
}

::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #071279 !important; /* Changes the outer ring color */
}

::ng-deep .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(7, 18, 121, 0.2) !important; /* Adds a subtle ripple effect */
}


.search-section .mat-form-field {
  padding-right: 0rem !important;
  width: 280px;
  margin-left: 2rem;
}

.search-section .mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0rem 0rem 0.4rem 0.4rem !important;
  border-radius: 0.5rem !important;
}


.app-blue
{
  color : var(--primary-color)
}

.search-section
  .mat-form-field-appearance-fill
  .mat-form-field-underline::before {
  background: none !important;
}

.search-section .mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0rem !important;
}

.search-section .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #d6d2d236 !important;
}

.search-section .mat-form-field-underline {
  width: 0% !important;
}

.feed-profile .mat-expansion-panel,
.feed-accordion .mat-expansion-panel {
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.activity-tabs .mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-transform: capitalize !important;
  font-size: larger !important;
  color: black !important;
  font-weight: 500 !important;
}

app-feed .mat-expansion-panel-header-title {
  font-size: 13px !important;
}

.navbar-nav {
  margin-bottom: 0.3rem;
}

// .navbar {
//   background-color: #ffffff !important;
//   height: 80px !important;
//   padding: 0rem 2rem 1rem 2rem !important;
// }

.main-tab-group .mat-tab-label-container {
  position: relative;
  top: 15%;
}

.footer {
  background-color: #002469 !important;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 3;
  height: 37px;
}

.referral .mat-card {
  -webkit-border-radius: 1rem !important;
  border-radius: 1rem !important;
}

app-conversation {
  position: relative;
}

.send-message-actions .mat-menu-content {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

// mat-card {
//   -webkit-box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%),
//     0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%) !important;
//   box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%),
//     0 2px 4px -1px rgb(0 0 0 / 30%) !important;
//   border-radius: 6px !important;
//   border: none !important;
//   font-family: var(--font-family) !important;
//   font-size: var(--font-size) !important;
// }

.discussion {
  box-shadow: none !important;
  border-radius: 15px !important;
  border: none !important;
}

.activity-tabs .mat-tab-header {
  align-self: center !important;
  border-bottom: none !important;
}

mat-tab {
  background-color: var(--background-color) !important;
}

// .mat-tab-label,
// .mat-tab-link,
// .mat-input-element,
// .mat-line {
//   font-family: var(--font-family) !important;
//   font-size: var(--font-size) !important;
//   color: var(--primary-color) !important;
// }

// ::ng-deep .mat-form-field {
//   font-family: var(--font-family) !important;
//   font-size: var(--font-size) !important;
//   color: #000000 !important;
//   font-weight: 400 !important;
// }

.hide-mat-input-underline .mat-form-field-underline {
  display: none !important;
}

mat-icon {
  cursor: pointer;
  font-family: 'Material Icons' !important;

}

::-webkit-scrollbar {
  width: 8px !important;
}

::-webkit-scrollbar-track {
  background: #f0f2f5 !important;
}

::-webkit-scrollbar-thumb {
  background-color: #374568 !important;
  border-radius: 10px !important;
  border: 3px solid lightgoldenrodyellow !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #374568 !important;
  border-radius: 10px !important;
  border: 1px solid lightgoldenrodyellow !important;
}

.feed-accordion .mat-expansion-panel-body {
  height: 20vh !important;
  overflow-y: scroll !important;
}

// Added for Networks Side menu hovering issue
.mat-expansion-panel-body {
  padding: 0px !important;
}

.feed-accordion .mat-expansion-panel-body {
  height: 20vh !important;
  overflow-y: scroll !important;
}

.feed-accordion .mat-expansion-panel-header {
  height: 5vh;
}

.bg-color-blue {
  background-color: var(--primary-color) !important;
  
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black !important;
  --mdc-radio-disabled-unselected-icon-color: black !important;
  --mdc-radio-unselected-hover-icon-color: #071279 !important; /* Unselected hover state */
  --mdc-radio-unselected-focus-icon-color: #071279 !important; /* Unselected focus state */
  --mdc-radio-unselected-icon-color: rgba(7, 18, 121, 0.54) !important; /* Unselected default icon */
  --mdc-radio-unselected-pressed-icon-color: rgba(7, 18, 121, 0.54) !important; /* Unselected pressed state */
  --mdc-radio-selected-focus-icon-color: #071279 !important; /* Selected focus state */
  --mdc-radio-selected-hover-icon-color: #071279 !important; /* Selected hover state */
  --mdc-radio-selected-icon-color: #071279 !important; /* Selected default icon */
  --mdc-radio-selected-pressed-icon-color: #071279 !important; /* Selected pressed state */
  --mat-radio-ripple-color: rgba(7, 18, 121, 0.1) !important; /* Ripple effect color */
  --mat-radio-checked-ripple-color: #071279 !important; /* Checked ripple effect color */
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38) !important;
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87) !important;
}


// .font-poppins {
//   font-family: "Poppins", sans-serif !important;
//   font-weight: 400 !important;
// }

.font-size-1 {
  font-size: 8px !important;
}

.font-size-2 {
  font-size: 10px !important;
}

.font-size-3 {
  font-size: 12px !important;
}

.font-size-4 {
  font-size: 14px !important;
}

.font-size-5 {
  font-size: 16px !important;
}

.cdk-overlay-container .cdk-overlay-pane .happy {
  background-color: var(--background-color) !important;
}

.cursor {
  cursor: pointer;
}

.ns-text-green {
  color: #7ebd41 !important;
}

.ns-bg-green {
  background-color: #7ebd41 !important;
}

.login-as
  .mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-outer-circle {
  border-color: #0d5097 !important;
}

.login-as .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #0d5097 !important;
}

.ns-text-red {
  color: #e95225 !important;
}

.ns-text-white {
  color: #ffffff !important;
}

.ns-bg-white {
  background-color: #ffffff !important;
}

.ns-bg-red {
  background-color: #e95225 !important;
}

.ns-text-yellow {
  color: #f8c538 !important;
}

.ns-bg-yellow {
  background-color: #f4b818 !important;
}

.ns-text-blue {
  color: var(--primary-color) !important;
}

.ns-bg-blue {
  background-color: var(--primary-color) !important;
}

.ns-bg-orange {
  background-color: #f3870c !important;
}

.ns-bg-light {
  background-color: #f0f2f5 !important;
}

// .banner-images-section .owl-carousel .owl-item {
//   float: right !important;
//   margin-right: 5px !important;
//   display: flex;
// }

.card-active {
  width: 350px;
  height: 240px;
  border-radius: 8px !important;
  border-style: solid !important;
  border-color: var(--primary-color) !important;
  cursor: text !important;
}

.card-dimensions {
  width: 350px;
  height: 240px;
  border-radius: 8px !important;
}

.card-image-box {
  width: 108px;
  border-radius: 8px !important;
  margin-left: 3rem;
  /* background-color: #374568; */
}

.card-grey-border
{
  border: 1px solid grey;
}

.card-image {
  width: 66px;
  height: 66px;
}

.card-height {
  height: 42vh !important;
  width: 23.5% !important;
}

// .card-height:hover {
//   background: repeating-linear-gradient(
//     36deg,
//     #53d5114d,
//     #0403544d 220px
//   ) !important;
// }

.explore-card {
  flex: 0 0 23%;
  margin: 5px;
  /* margin: 5px 15px 5px 0px; */
  border-radius: 0.5rem !important;
  padding: 14px 30px 15px 25px !important;
}

.explore-card-body {
  height: 36vh;
}

// .explore-card:hover .btn {
//   background-color: var(--primary-color) !important;
//   cursor: pointer;
//   color: white !important;
// }

.profile-image {
  height: 80px;
  width: 80px;
}

span {
  cursor: pointer;
}

.mat-button-disabled span {
  cursor: not-allowed !important;
}

.explore-card:hover {
  background: repeating-linear-gradient(36deg, #53d5114d, #0403544d 220px);
  cursor: pointer;
}

.btn-save {
  background-color: var(--primary-color);
  color: white !important;
}

.button-cancel {
  background-color: #e95225 !important;
  color: white !important;
}

.verified {
  font-size: 14px;
  color: rgb(10, 177, 10) !important;
  margin-left: 0px !important;
}

.verified-discussion {
  font-size: 14px;
  /* color: rgb(10, 177, 10) !important; */
  margin-left: 0px !important;
}

.verified-suggestions {
  font-size: 14px;
  color: rgb(10, 177, 10) !important;
  /* margin-left: 5px !important; */
}

/* user info  */
.feed-name {
  font-weight: bold !important;
  font-size: 14px !important;
}

.feed-name-suggestion {
  font-weight: bold !important;
  font-size: 11px !important;
}

.activity-section mat-card {
  padding: 0px;
}

.feed-specialization {
  font-weight: 400 !important;
  font-size: 10px !important;
}

.userDHeight {
  height: 20% !important;
}

.feedUHeight {
  height: 28% !important;
}

.scrollable {
  width: 100%;
  height: 70h;
  overflow: auto;
}

/* .card-header{
  margin: 0px;
  height: 10px;
} */

.content-wrap {
  display: inline-block;
  word-break: break-word;
}

/* live chat CSS */

#ng-chat.light-theme .secondary-background {
  background-color: var(--primary-color) !important;
  border-radius: 5px 5px 0px 0px !important;
  color: white !important;
  box-shadow: none !important;
}

#ng-chat.light-theme .primary-outline-color {
  border-color: transparent;
  border-radius: 5px 5px 0px 0px;
}

#ng-chat.light-theme .friends-search-bar {
  background-color: #fff;
  border: none;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 4px 0 rgb(0 0 0 / 12%),
    0 1px 10px -10px rgb(0 0 0 / 30%) !important;
  border-radius: 15px;
}

#ng-chat.light-theme .primary-outline-color {
  border: none;
}

#ng-chat.light-theme .chat-window-input {
  background-color: #fff;
  border: 1px solid #8080802e;
  /* box-shadow: 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 4px 0 rgb(0 0 0 / 12%), 0 1px 1px -10px rgb(0 0 0 / 30%)!important; */
  border-radius: 15px;
}

.referrals-dialog {
  max-width: none !important;
  max-height: 100% !important;
  pointer-events: auto !important;
  width: 95% !important;
  height: 90vh !important;
  position: static !important;
}

.ng-chat-close {
  color: white !important;
  font-weight: 600;
  text-decoration: none;
}

.fixed {
  position: sticky;
  top: 0;
  z-index: 999;
}

.background-grey {
  background-color: #f5f5f5 !important;
  border: 0 !important;
}

.label-blue {
  color:var(--primary-color) !important;
}

.label-black {
  color: #000000 !important;
  // margin: 0.15rem;
}

.btn-nushift-blue {
  background-color: var(--primary-color) !important;
  border: 0 !important;
  color: white !important;
}

.nushift-blue {
  color: var(--primary-color)  !important;
}

.create-post-dialog .mat-dialog-container {
  max-width: 50vw !important;
  max-height: 60vh !important;
  min-width: 40vw !important;
  height: 40vh !important;
}

.full-width-dialog-group .mat-dialog-container {
  max-width: 50vw !important;
  min-width: 40vw !important;
  height: 70vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.groups-invite-class .mat-dialog-container {
  max-width: 50vw !important;
  min-width: 40vw !important;
  height: 65vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.groups .mat-tab-label-active .mat-tab-label-content {
  color: black !important;
  font-size: 18px !important;
}

.groups .mat-tab-label .mat-tab-label-content {
  color: black !important;
  font-size: 16px !important;
}

.practice-area-dialog .mat-dialog-container {
  max-width: 50vw !important;
  /* min-height: 500px !important; */
  min-width: 40vw !important;
  overflow-y: scroll;
  height: 500px;
}

.group-area-dialog .mat-dialog-container {
  max-width: 50vw !important;
  min-width: 40vw !important;
}

@media all and (max-width: 500px) {
  .full-width-dialog .mat-dialog-container {
    max-width: 90vw !important;
    max-height: 80% !important;
    width: 90vw !important;
  }

  .practice-area-dialog .mat-dialog-container {
    max-width: 90vw !important;
    min-height: 90vh !important;
    max-height: 80% !important;
    width: 90vw !important;
  }
}

.feed-content .owl-carousel .owl-item img {
  /* height: 350px; */
  /* max-height: 350px; */
  max-width: 100% !important;
  width: unset !important;
}

.jobs-main-section .jobs-image-section {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.jobs-main-section .save-jobs-image-section {
  flex: 0 0 25% !important;
  max-width: 25% !important;
}

.highlightText {
  color: #007bff !important;
  text-decoration: none !important;
}

.highlightText:hover {
  text-decoration: underline !important;
}

.bar {
  /* margin-left: 20rem !important; */
  background-color: darkgray !important;
  height: 100% !important;
  border-radius: 10rem;
  /* left: 42.2rem;
  top: 0.3rem;
  position: absolute; */
}

.discussion-icon {
  top: 1rem;
  position: absolute;
  right: 1rem;
}

.mat-tab-body-content {
  overflow: hidden !important;
}

.groups .mat-tab-body-content {
  overflow-y: scroll !important;
  height: 75vh;
  margin-bottom: 2rem;
}

.buttons .mat-dialog-content {
  display: block;
  margin: 0px auto;
  max-height: 65vh;
  width: 18rem;
  padding: 1rem;
  font-size: 1.67rem;
  overflow: auto;
  border-radius: 2rem;
  text-align: center;
  background-color: grey;
  cursor: pointer;
}

.discussion-board-dialog .mat-dialog-container {
  max-width: 50vw !important;
  max-height: 60vh !important;
  min-width: 40vw !important;
  height: 65vh !important;
  top: 12rem;
  position: relative;
}

.question-list-dialog .mat-dialog-container {
  height: 70vh !important;
  position: relative;
  overflow-x: hidden;
}

.comments .mat-card {
  background-color: #a9a9a94d !important;
}

.discussion-board-snackbar {
  background-color: #a30e0e !important;
  text-align: center !important;
}

.search-input mat-label {
  top: 0.5rem !important;
}

.search-input .mat-form-field-wrapper {
  padding-bottom: 1.7rem !important;
  right: 15rem !important;
}

.search-input-practice-area {
  margin-right: -12rem !important;
  line-height: 0 !important;
}

.search-input-practice-area .mat-form-field-wrapper {
  padding-bottom: 1.7rem !important;
  right: 25rem !important;
  width: 165%;
  top: 1rem;
}

::ng-deep .mat-tab-list .mat-tab-labels .mat-tab-label-active {
  color: black !important;
}

.delete-button .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgb(0 0 0 / 72%) !important;
}

.practice-area-field .mat-form-field-flex {
  border-radius: 4px !important;
  background-color: white !important;
}

.post-permissions-field {
  .mat-form-field-wrapper {
    padding: 0rem !important;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-flex {
      height: 30px !important;
      width: 150px !important;
    }

    .mat-select-arrow-wrapper {
      transform: translateY(-5%) !important;
    }

    .mat-form-field-infix {
      padding: 0;
    }
  }
}

.nushift-blue-btn {
  background-color: var(--primary-color) !important;
}

app-create-event {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0rem 0rem 5rem 0rem;
}

app-edit-business-page,
app-others-profile {
  // height: 85vh;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
}

app-chat .mat-tab-body-content {
  transform: none;
  overflow-y: scroll !important;
  height: 75vh !important;
  padding-bottom: 1rem !important;
}

app-subscriptions {
  // height: 60vh;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
}

app-forgot-password {
  height: 100vh !important;
  display: block !important;
}

.navbar-expand-lg .navbar-collapse {
  display: block !important;
}

.sent-card {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 45vh !important;
}

.navbar-section {
  .search-field {
    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
      padding: 0rem !important;
      position: relative;
      height: 35px !important;
      background-color: white !important;
      border-radius: 0.75rem !important;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
      height: 0px !important;
    }

    .mat-form-field-label-wrapper {
      top: 0% !important;
    }

    .mat-form-field-label {
      top: 0% !important;
      left: 12% !important;
      font-size: 16px;
    }

    .mat-form-field-flex {
      position: relative;

      .mat-form-field-prefix {
        white-space: nowrap;
        flex: none;
        position: absolute;
        top: 18% !important;
        left: 3%;
      }
    }
  }
}

.networks-dropdown {
  .mat-form-field-flex {
    height: 30px !important;

    .mat-form-field-infix {
      padding: 0px !important;
    }
  }
}

// Likes,comments,shares font awesome icons CSS
.fa-heart,
.fa-heart-o,
.fa-commenting-o,
.fa-share-square-o {
  font-size: 22px;
}

.fa-heart {
  color: rgb(243, 126, 145);
}

// Comment field CSS in feed-preview screen
.each-feed-card {
  .post-comment-field {
    .mat-form-field-flex {
      padding: 0 !important;

      .mat-form-field-infix {
        padding: 0.25rem 0.5rem 0.75rem 0.5rem !important;
      }
    }

    .mat-form-field-wrapper {
      margin: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

// .commented-user-image {
//   width: 40px !important;
//   height:  40px !important;
// }
// .commented-user-image {
//   width: 60px !important;
// }
.mat-expansion-panel-header.mat-expanded {
  height: 48px;
}

// chat component

.chat-main-div {
  mat-form-field {
    background-color: #efe7e752;
  }
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background: none !important;
  }
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    border: 1px solid #efe7e752;
    border-radius: 0.5rem;
  }
  .mat-form-field-flex {
    padding-left: 15px;
    align-items: center;
    /* padding-right: 10px; */
  }
  .mat-form-field-appearance-legacy .mat-form-field-ripple {
    height: 0px;
  }
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0px;
  }
  .mat-form-field-infix {
    border-top: 0px solid black;
  }
}

//converstion.html

.input-search .mat-form-field-label {
  top: 2em;
  left: 1em !important;
}

/* app-book-appointment {
  height: 40vh;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
} */
.my-drawer{
// Notifications Tabs
.mat-tab-label {
  font-size: 12px !important;
  height: 20px !important;
  min-width: 70px !important;
  padding: 0px !important;
  font-family: "Inter", sans-serif !important;
}
}
::ng-deep .mat-ink-bar {
  width: 10% !important; /* Change the width to your desired value */
}

:host ::ng-deep .mat-tab-group.mat-primary .mat-ink-bar {
  background-color: rgb(294, 121, 143) !important;
}

  .create-group-form {
  .mat-input-element {
    border: 1px solid #ccc; /* Border color and width */
    border-radius: 4px;
    height: 40px !important; /* Border radius */
  }
  /* Hover effect */
  .mat-input-element:hover {
    border-color: #999; /* Change border color on hover */
  }

  /* Focus effect */
  .mat-input-element:focus {
    border-color: #007bff; /* Change border color on focus */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Add a box shadow for focus effect */
  }
  .mat-form-field-label {
    font-weight: bold;
    font-size: 12px;
  }
  /* Remove the bottom line from mat-input */
  .mat-form-field-underline {
    display: none !important; /* Hide the bottom line */
  }

  /* Optional: Adjust padding to align text with adjacent elements */
  .mat-form-field {
    padding-bottom: 0; /* Adjust padding as needed */
    width: 100% !important;
  }
}

.groups-main-container {
  .mat-card
  {
    padding: 2px !important;
  }
  h3
  {
    color: var(--primary-color) !important;
  }
}

.apply-job-form {
  .mat-input-element {
    border: 1px solid #ccc; /* Border color and width */
    border-radius: 4px;
    height: 40px !important; /* Border radius */
  }
  /* Hover effect */
  .mat-input-element:hover {
    border-color: #999; /* Change border color on hover */
  }

  /* Focus effect */
  .mat-input-element:focus {
    border-color: #007bff; /* Change border color on focus */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Add a box shadow for focus effect */
  }
  .mat-form-field-label {
    font-weight: bold;
    font-size: 12px;
  }
  /* Remove the bottom line from mat-input */
  .mat-form-field-underline {
    display: none; /* Hide the bottom line */
  }

  /* Optional: Adjust padding to align text with adjacent elements */
  .mat-form-field {
    padding-bottom: 0; /* Adjust padding as needed */
    width: 100% !important;
  }
}
//.create-group-main-container{
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
    background-color: var(--primary-color) !important;
  }
  
  .mat-radio-button.mat-accent .mat-radio-outer-circle {
    border-color: var(--primary-color) !important;
  }
  
  .mat-checkbox-checked.mat-primary .mat-checkbox-background,
  .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
    background-color: var(--primary-color) !important;
  }
  
  .mat-checkbox-checked.mat-primary .mat-checkbox-frame {
    border-color: var(--primary-color) !important;
  }
//}

.top-ink-bar .mat-tab-group {
  overflow: visible !important;
}

.top-ink-bar .mat-tab-header {
  overflow: visible !important;
}

.top-ink-bar .mat-ink-bar {
  top: 0;
  bottom: auto;
  height: 2px;
  background-color: #3f51b5; /* Customize the color as needed */
}

.top-ink-bar .mat-tab-label {
  padding-bottom: 8px; /* Adjust the padding to create space for the ink bar */
}
@media (max-width: 767px) {
  .navbar-section
  {
    display: none;
  }
  .side-navbar-section
  {
    display: none;
  }
  

  /* Show mobile navbar with hamburger menu */
  .mobile-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #0e0436;
  }

  .menu-icon {
    font-size: 24px;
    cursor: pointer;
  }

  /* Mobile Menu Styles */
  .mobile-menu {
    display: none;
    flex-direction: column;
    background-color: #333;
    padding: 10px;
    position: absolute;
    // top: 60px;
    width: 100%;
  }

  .mobile-menu.open {
    display: flex;
  }

  .mobile-menu-item {
    padding: 15px;
    color: white;
    text-align: center;
    border-bottom: 1px solid #444;
  }
  .custom-navbar {
    background-color: var(--primary-color) !important;
    /* Light blue background */
  }

/* Base styling for the custom toggle */
.custom-icon-toggle .mat-slide-toggle-thumb {
  background: none; /* Remove the default thumb background */
  width: 24px;
  height: 24px;
}

/* Add the custom icon using a pseudo-element */
.custom-icon-toggle .mat-slide-toggle-thumb::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-image: url('/assets/privacy-settings/on.png'); /* Path to your custom icon */
  background-size: cover;
  background-position: center;
}

/* When the toggle is checked, change the icon */
.custom-icon-toggle.mat-checked .mat-slide-toggle-thumb::before {
  background-image: url('/assets/privacy-settings/off.png'); /* Path to the checked icon */
}


  
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #4caf50 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #80e27e !important;
}

.mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: #9e9e9e !important;
}

.mat-slide-toggle .mat-slide-toggle-bar {
  background-color: #dcdcdc !important;
}

::ng-deep .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: green !important; /* Background color */
  --mdc-snackbar-supporting-text-color: white !important; /* Text color */
}
.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: green !important; /* Change background color */
  --mdc-snackbar-supporting-text-color: white !important; /* Change text color */
}

.success-snackbar {
  color: white !important; /* Ensure text is white */
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  border-radius: 6px;
}
